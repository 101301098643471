*:focus-visible,*:focus{
    outline: unset!important;
    box-shadow: unset!important;
}
*:not(.flatpickr-input)[readonly]{
    background-color: rgba(216, 216, 216, 0.5)!important;
}
body{
    background-color: #F7F7F7;
}
.rmdp-container {
    display: block !important;
}